/* eslint-disable no-console */
import { node } from 'prop-types';
import { useEffect, useState } from 'react';
import { checkVersionMismatch } from 'utils/checkVersionMismatch';
import packageJson from '../../../package.json';

global.appVersion = packageJson.version;
export function HandleCache({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isLatestVersion, setIsLatestVersion] = useState(false);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      fetch(`/meta.json?${new Date().getTime()}`, { cache: 'no-cache' })
        .then((res) => res.json())
        .then((meta) => {
          const latestVersion = meta.version;
          const currentVersion = global.appVersion;
          const shouldForceRefresh = checkVersionMismatch(
            latestVersion,
            currentVersion,
          );

          if (shouldForceRefresh) {
            console.log(
              `New version - ${latestVersion} available. Need to force refresh`,
            );
            setIsLoading(false);
            setIsLatestVersion(false);
            return;
          }
          setIsLoading(false);
          setIsLatestVersion(true);
        });
    }
    setIsLoading(false);
    setIsLatestVersion(true);
  }, []);

  const clearCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');

    if (caches) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          if (!name.includes('.svg') && !name.includes('.png')) {
            caches.delete(name);
          }
        });
      });
      window.location.reload(true);
    }
  };

  if (isLoading) {
    return null;
  }

  if (!isLatestVersion) {
    clearCacheAndReload();
  }

  return children;
}

HandleCache.propTypes = {
  children: node.isRequired,
};
