import DeleteData from 'pages/DeleteData';
import { HomePage } from 'pages/HomePage';
import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/*" element={<HomePage />} />
      <Route path="/delete-data/*" element={<DeleteData />} />
    </Routes>
  </BrowserRouter>
);

export default Router;
