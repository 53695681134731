import { AppContainer } from 'components/AppContainer';
import React from 'react';
import Router from './routes';
import { HandleCache } from './components/HandleCache';

function App() {
  return (
    <HandleCache>
      <AppContainer>
        <Router />
      </AppContainer>
    </HandleCache>
  );
}

export default App;
