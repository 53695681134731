import React, { useState } from 'react';

import { Images } from 'assets';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Container, Form, Image, Toast } from './styles';

function DeleteData() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [agree, setAgree] = useState(false);
  const [responseLoginData, setResponseLoginData] = useState({});
  const [messageError, setMessageError] = useState('');
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState({
    Username: '',
    Password: '',
  });
  const handleLogin = async () => {
    setLoading(true);
    await fetch(
      'https://dindim-dev-api.azurewebsites.net/api/sysUser/Authenticate',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Username: credential?.Username,
          Password: credential?.Password,
        }),
      },
    ).then((res) => {
      if (res.status === 200) {
        res.json().then((data) => {
          setResponseLoginData(data);
        });
        setLoading(false);
        setCredential({ Username: '', Password: '' });
        setStep(1);
      }
    });

    setLoading(false);
  };

  const handleDeleteAccount = async () => {
    setLoading(true);
    setMessageError('');
    await fetch(
      `https://dindim-dev-api.azurewebsites.net/api/sysUser/DeleteAccount/${responseLoginData?.Account.AccountId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${responseLoginData?.Token}`,
        },
      },
    )
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          setMessageSuccess(true);
          setTimeout(() => {
            navigate('/');
          }, 3000);
        }
        return res.json(res);
      })
      .then((data) => {
        setMessageError(data?.ErrorMsg);
        setTimeout(() => {
          setMessageError('');
        }, 5000);
      });

    setLoading(false);
  };

  return (
    <Container>
      {step === 0 && (
        <Box>
          <div>
            <Image src="./logo.png" alt="" />
          </div>
          <Form>
            <input
              type="phone"
              placeholder="Telemóvel"
              autoComplete="off"
              onChange={(e) =>
                setCredential({ ...credential, Username: e.target.value })
              }
            />
            <input
              type="password"
              placeholder="Senha"
              autoComplete="off"
              onChange={(e) =>
                setCredential({ ...credential, Password: e.target.value })
              }
            />
          </Form>

          <Button type="submit" onClick={handleLogin}>
            {loading && <img width={25} src={Images.loading} alt="" />}
            <span>Acessar</span>
          </Button>
        </Box>
      )}
      {step === 1 && (
        <Box>
          <div>
            <Image src="./logo.png" alt="" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <span>
              Todos os dados serão permanentemente eliminados, incluindo: Nome,
              Telemóvel, Email, Palavra-passe. Não retemos dados por qualquer
              período específico; serão permanentemente removidos.{' '}
              <strong style={{ color: 'red' }}>
                Por favor, antes de prosseguir confirme que fez o levantamento
                de todo valor de sua conta.
              </strong>
            </span>
            <div
              style={{
                display: 'flex',
                gap: '10px',
                width: '100%',
                flexDirection: 'row',
              }}
            >
              <input
                type="checkbox"
                onClick={() => setAgree((prev) => !prev)}
              />
              <span>Aceito</span>
            </div>
          </div>

          <Button type="submit" onClick={handleDeleteAccount} disabled={!agree}>
            {loading && <img width={25} src={Images.loading} alt="" />}
            <span>Eliminar dados</span>
          </Button>
        </Box>
      )}
      {messageError !== '' && (
        <Toast show={messageError} color={messageError !== '' && 'red'}>
          <div
            className="close"
            onClick={() => setMessageError('')}
            aria-hidden="true"
          >
            +
          </div>
          <span className="msg">{messageError}</span>
        </Toast>
      )}
      {messageSuccess && (
        <Toast show={messageSuccess} color={messageSuccess && 'green'}>
          <div
            className="close"
            onClick={() => setMessageSuccess(false)}
            aria-hidden="true"
          >
            +
          </div>
          <span className="msg">Conta eliminada com sucesso!</span>
        </Toast>
      )}
    </Container>
  );
}

export default DeleteData;
