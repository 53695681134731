import logo from './logo.png';
import slogan from './dindim-slogan.png';
import banner from './banner-img.png';
import slide1 from './carousel/carousel-1.png';
import slide2 from './carousel/carousel-2.png';
import slide3 from './carousel/carousel-3.png';
import slide4 from './carousel/carousel-4.png';
import play from './play.svg';
import apple from './apple.svg';
import promo from './promo.png';
import background from './bg-dindim.png';
import banner1 from './banner1.jpg';
import banner2 from './banner2.jpg';
import loading from './loading.gif';

export const Images = {
  logo,
  play,
  apple,
  promo,
  background,
  slogan,
  banner,
  slide1,
  slide2,
  slide3,
  slide4,
  banner1,
  banner2,
  loading,
};
