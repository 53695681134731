/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import ArrowIcon from 'assets/arrow';
import React, { useRef, useState } from 'react';
import Slider from 'react-animated-slider';
import { Images } from '../../assets';
import 'react-animated-slider/build/horizontal.css';
import Slide from './Slider';
import {
  Banner,
  BannerContent,
  BannerImg,
  BannerSubTitle,
  BannerTitle,
  Carousel,
  CarouselButton,
  CarouselContent,
  Container,
  Content,
  Footer,
  FooterInfos,
  Header,
  HeaderContent,
  InfoAddress,
  ListFooterMenu,
  StoreButton,
  WideContainer,
  WrapperFooter,
  WrapperInfos,
  WrapperStores,
} from './styles';

export function HomePage() {
  const [sliderNumber, setSliderNumber] = useState(1);
  const topHef = useRef();
  const imgSlide = useRef();

  const slide = [
    { img: Images.banner },
    { img: Images.banner1 },
    { img: Images.banner2 },
  ];

  function scrollToTop() {
    topHef.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <WideContainer>
      <Header ref={topHef}>
        <HeaderContent>
          <img src={Images.logo} alt="dindim" />
          <img
            src={Images.slogan}
            alt="Rápido Seguro Cómodo"
            className="sloganImg"
            draggable={false}
          />
        </HeaderContent>
      </Header>
      <Container>
        <Banner>
          <BannerContent>
            <div className="content">
              <BannerTitle>
                O app <b>dindim</b> chegou para <b>facilitar</b> a sua vida
              </BannerTitle>
              <BannerSubTitle>
                Descarregue agora em seu telemóvel
              </BannerSubTitle>
              <WrapperStores>
                <a
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.dindim"
                  rel="noreferrer"
                >
                  <StoreButton>
                    <img src={Images.play} alt="GooglePlay" />
                    <div>
                      <span>Disponível no</span>
                      <h3>Google Play</h3>
                    </div>
                  </StoreButton>
                </a>
                <a
                  target="_blank"
                  href="https://www.apple.com/app-store"
                  rel="noreferrer"
                >
                  <StoreButton>
                    <img src={Images.apple} alt="App Store" />
                    <div>
                      <span>Disponível na</span>
                      <h3>App Store</h3>
                    </div>
                  </StoreButton>
                </a>
              </WrapperStores>
            </div>
          </BannerContent>
          <BannerImg>
            <Slider infinite autoplay={4000}>
              {slide.map((img, index) => (
                <img key={index} ref={imgSlide} src={img.img} alt="slide" />
              ))}
            </Slider>
          </BannerImg>
        </Banner>
        <Carousel>
          <CarouselButton
            type="button"
            onClick={() =>
              sliderNumber === 1
                ? setSliderNumber(4)
                : setSliderNumber(sliderNumber - 1)
            }
          >
            <ArrowIcon />
          </CarouselButton>
          <CarouselContent className="fade">
            <Slide
              sliderNumber={sliderNumber}
              setSliderNumber={setSliderNumber}
            />
          </CarouselContent>
          <CarouselButton
            type="button"
            right
            onClick={() =>
              sliderNumber === 4
                ? setSliderNumber(1)
                : setSliderNumber(sliderNumber + 1)
            }
          >
            <ArrowIcon />
          </CarouselButton>
        </Carousel>
        <Content>
          <WrapperInfos>
            <p>
              Descarregue agora e desfrute dos benefícios que o <b>dindim</b>{' '}
              lhe oferece.
            </p>
          </WrapperInfos>
          <WrapperStores>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.dindim"
              rel="noreferrer"
            >
              <StoreButton>
                <img src={Images.play} alt="GooglePlay" />
                <div>
                  <span>Disponível no</span>
                  <h3>Google Play</h3>
                </div>
              </StoreButton>
            </a>
            <a
              target="_blank"
              href="https://www.apple.com/app-store"
              rel="noreferrer"
            >
              <StoreButton>
                <img src={Images.apple} alt="App Store" />
                <div>
                  <span>Disponível na</span>
                  <h3>App Store</h3>
                </div>
              </StoreButton>
            </a>
          </WrapperStores>
        </Content>
      </Container>
      <WrapperFooter>
        <FooterInfos>
          <ListFooterMenu>
            <img src={Images.logo} alt="dindim" />
            <ul>
              <li onClick={() => scrollToTop()}>Home</li>
              <li>
                <a target="_blank" href="/privacy-term.html">
                  Privacidade
                </a>
              </li>
              <li>
                <a target="_blank" href="/use-term.html">
                  Termos de Uso
                </a>
              </li>
              <li>
                <a target="_self" href="/delete-data">
                  Solicitar eliminação de dados
                </a>
              </li>
            </ul>
          </ListFooterMenu>
          <InfoAddress>
            <h2>Humuos - Tecnologias, LDA</h2>
            <h5>Nova Vida, Rua 13, Nº 234, Luanda Angola</h5>
            <h5>E-mail: info@dindim.ao</h5>
            <h5>Tel: +244 2 1234-5678</h5>
          </InfoAddress>
        </FooterInfos>

        <Footer>
          © Copyright 2022 - dindim - Todos os direitos reservados
        </Footer>
      </WrapperFooter>
    </WideContainer>
  );
}
