/* eslint-disable no-underscore-dangle */
export const checkVersionMismatch = (latestVersion, currentVersion) => {
  const _latestVersion = latestVersion.split(/\./g);

  const _currentVersion = currentVersion.split(/\./g);

  while (_latestVersion.length || _currentVersion.length) {
    const a = Number(_latestVersion.shift());

    const b = Number(_currentVersion.shift());

    // eslint-disable-next-line no-continue
    if (a === b) continue;

    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }

  return false;
};
