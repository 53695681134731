import { node } from 'prop-types';
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'theme';
import GlobalStyle from 'theme/global';
import { MainContainer } from './styles';

export function AppContainer({ children }) {
  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <MainContainer>{children}</MainContainer>
    </ThemeProvider>
  );
}

AppContainer.propTypes = {
  children: node.isRequired,
};
