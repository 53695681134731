import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 1s;
`;

export const SliderContent = styled.div`
  width: 100%;
  height: calc(100% - 20px);

  display: flex;
  flex-direction: ${({ left }) => (left ? 'row' : 'row-reverse')};
  align-items: center;
  justify-content: center;
  gap: 20px;

  img {
    height: 280px;
  }

  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const WrapperInfo = styled.div`
  text-align: ${({ left }) => (left ? 'left' : 'right')};

  @media (max-width: 700px) {
    text-align: center;
  }
`;

export const Title = styled.div`
  font-size: 1.2rem;
  font-weight: 500;
  color: #000f4a;
  /* max-width: 500px; */
`;
export const Description = styled.div`
  font-size: 0.9rem;
  color: #717171;
  max-width: 550px;
`;

export const WrapperIndicator = styled.div`
  display: flex;
  gap: 6px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;

export const Indicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;

  background-color: ${({ active }) => (active ? '#000F4A' : '#B9B9B9')};
  font-size: 0.9rem;
  max-width: 550px;
`;
