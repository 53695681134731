import React from 'react';

import { Images } from 'assets';
import { func, number } from 'prop-types';
import {
  Container,
  Description,
  Indicator,
  SliderContent,
  Title,
  WrapperIndicator,
  WrapperInfo,
} from './styles';

function Slide({ sliderNumber, setSliderNumber }) {
  const slideData = [
    {
      id: 1,
      title: 'Revolucione o jeito que você usa seu dinheiro com o app dindim',
      desc: 'Você pode gerir seus gastos, pagar e receber trocos em estabelecimentos,  pagar e cobrar usuários, além de poder receber benefícios.',
      img: Images.slide1,
      imgPosition: 'left',
    },
    {
      id: 2,
      title: 'Acompanhe todos os seus gastos em tempo real',
      desc: 'Controle seus gastos, reveja suas transações de recargas, débitos e créditos por meio do nosso filtro de buscas.',
      img: Images.slide2,
      imgPosition: 'right',
    },
    {
      id: 3,
      title: 'Pague com o Código QR',
      desc: 'Você pode pagar pessoas e estabelecimentos de maneira fácil por meio do Código QR, é bem mais rápido e prático!',
      img: Images.slide3,
      imgPosition: 'left',
    },
    {
      id: 4,
      title: 'Interaja com outros utilizadores através do chat',
      desc: 'Por meio do chat, converse, pague e cobre seus amigos e conhecidos, assim fica bem mais divertido e prático.',
      img: Images.slide4,
      imgPosition: 'right',
    },
  ];

  const currentSlide = slideData.find((obj) => obj.id === sliderNumber);

  return (
    <Container>
      <SliderContent left={currentSlide.imgPosition === 'left'}>
        <img alt={currentSlide.title} src={currentSlide.img} />
        <WrapperInfo left={currentSlide.imgPosition === 'left'}>
          <Title>{currentSlide.title}</Title>
          <Description>{currentSlide.desc}</Description>
        </WrapperInfo>
      </SliderContent>
      <WrapperIndicator>
        {slideData.map((item) => (
          <Indicator
            key={item.id}
            active={item.id === sliderNumber}
            onClick={() => setSliderNumber(item.id)}
          />
        ))}
      </WrapperIndicator>
    </Container>
  );
}

export default Slide;

Slide.propTypes = {
  sliderNumber: number.isRequired,
  setSliderNumber: func.isRequired,
};
