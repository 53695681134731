import styled from 'styled-components';

export const WideContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: #f3f3f3;
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  border-bottom: 0.5px solid #d5d5d530;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000f4a;

  @media (max-width: 600px) {
    height: 80px;
  }
`;

export const HeaderContent = styled.div`
  height: 60px;
  width: 100%;
  max-width: 1400px;
  border-bottom: 0.5px solid #d5d5d530;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 45px;

  img {
    height: 35px;
  }

  .sloganImg {
    height: 80%;
    user-select: none;
  }

  @media (max-width: 600px) {
    height: 80px;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding-top: 8px;

    img {
      height: 25px;
    }

    .sloganImg {
      height: 35px;
      user-select: none;

      @media (max-width: 280px) {
        width: 150px;
        height: 28px;
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 100%;
  padding: 16px 45px 0 45px;
  max-width: 1400px;
  height: calc(100% - 60px);
  background-size: cover;

  @media (max-width: 1000px) {
    padding: 12px 12px;
  }
`;

export const Banner = styled.div`
  width: 100%;
  max-width: 1400px;
  height: fit-content;

  display: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;

  @media (max-width: 1225px) {
    height: 450px;
    justify-content: space-between;
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    height: fit-content;
    min-height: fit-content;
  }
`;

export const BannerContent = styled.div`
  width: calc(100% - 650px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 45px;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 380px) {
      align-items: center;
    }
  }

  @media (max-width: 1225px) {
    width: calc(100% - 532px);
  }

  @media (max-width: 1000px) {
    width: 100%;
    padding: 32px;
  }
`;

export const BannerTitle = styled.div`
  font-size: 1.6rem;
  color: #000f4a;

  @media (max-width: 380px) {
    text-align: center;
  }
`;
export const BannerSubTitle = styled.div`
  font-size: 1rem;
  color: #000f4a;

  @media (max-width: 380px) {
    text-align: center;
  }
`;

export const BannerImg = styled.div`
  width: 650px;
  height: fit-content;
  background-position: center;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;

  .slider {
    height: 550px;
    a {
      display: none;
    }
  }

  @media (max-width: 1225px) {
    width: 532px;
    .slider {
      width: 532px;
      height: 450px;
    }
  }

  @media (max-width: 1000px) {
    width: 100%;
    height: 75vw;

    .slider {
      width: 100%;
      height: 100%;
    }
  }
`;

export const Carousel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 400px !important;
  padding: 0 16px;
  background-color: #fff;

  @media (max-width: 700px) {
    min-height: 600px;
  }
  @media (max-width: 400px) {
    min-height: 700px;
  }
`;

export const CarouselButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: none;
  background-color: #f5f5f5;

  transform: ${({ right }) => right && 'rotate(180deg)'};

  :hover {
    cursor: pointer;
  }

  @media (max-width: 700px) {
    transform: ${({ right }) => right && 'rotate(180deg)'} scale(0.8);
  }
`;

export const CarouselContent = styled.div`
  width: calc(100% - 130px);
  height: 90%;

  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`;

export const TitlePage = styled.div`
  color: #fff;
  font-size: 1.2rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 900px) {
    position: static;
    padding-left: 12px;
    margin-left: 12px;
    transform: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: fit-content;
  min-height: 250px;
  display: flex;
  gap: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0ebd5;
  padding: 20px;
`;

export const WrapperInfos = styled.div`
  color: #4f8b15;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 1.2rem;
    }

    @media (max-width: 350px) {
      text-align: center;
    }
  }

  b {
    font-weight: 600;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 600;

    @media (max-width: 900px) {
      font-size: 2rem;
    }
  }
`;

export const WrapperStores = styled.div`
  display: flex;
  gap: 18px;

  @media (max-width: 380px) {
    flex-direction: column;
  }

  a {
    background-color: #ffffff;
    border-radius: 12px;

    @media (max-width: 520px) {
      width: 49%;
    }
    @media (max-width: 380px) {
      width: 100%;
    }
  }
`;

export const StoreButton = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 200px;
  border: 2px solid #81c242;
  border-radius: 12px;
  padding: 8px 16px;
  cursor: pointer;

  div {
    margin-top: -4px;
  }

  span {
    white-space: nowrap;
    font-size: 0.7rem;
  }

  h3 {
    font-size: 0.9rem;
    margin-top: -4px;
    white-space: nowrap;
  }

  img {
    height: 32px;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }
  @media (max-width: 1000px) {
    width: 200px;
  }
  @media (max-width: 520px) {
    width: 100%;
  }
`;

export const WrapperFooter = styled.div`
  margin-top: 16px;
  width: 100%;
  height: fit-content;
  min-height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #000f4a;
  border-top: 5px solid #81c242;
  position: relative;
`;

export const FooterInfos = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 450px) {
    padding: 30px;
    flex-direction: column;
    gap: 40px;
  }
`;

export const ListFooterMenu = styled.div`
  width: 50%;
  height: fit-content;
  color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 50px;
  border-right: 1px solid #eee;

  @media (max-width: 450px) {
    width: 100%;
    border-right: none;
    padding: 0 20px;
  }

  img {
    width: 150px;
    align-self: flex-end;
    margin-right: 90px;
    @media (max-width: 450px) {
      align-self: center;
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-end;

    @media (max-width: 450px) {
      align-self: center;
    }
    li {
      list-style: none;
      text-align: left;
      user-select: none;

      :hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
`;

export const InfoAddress = styled.div`
  width: 50%;
  height: fit-content;
  color: white;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 50px;

  @media (max-width: 450px) {
    width: 100%;
    justify-content: center;
    padding: 20px;
    border-top: 1px solid #eee;
  }

  h2 {
    align-self: flex-start;
    font-weight: 500;
    font-size: 1.2rem;
    @media (max-width: 450px) {
      align-self: center;
      text-align: center;
    }
  }
  h5 {
    align-self: flex-start;
    font-size: 0.8rem;
    font-weight: 100;
    @media (max-width: 450px) {
      align-self: center;
      text-align: center;
    }
  }
`;

export const Footer = styled.div`
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.7rem;
  width: 100%;
  height: 30px;
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 5px 0;
`;
