import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  background-color: #d7d9de;
  position: relative;
  overflow: hidden;
`;

export const Box = styled.div`
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  background-color: #fcfcfc;
  border-radius: 12px;
  padding: 20px 45px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  span {
    font-size: 0.8rem;
  }
`;

export const Image = styled.img`
  height: 51px;
`;

export const Form = styled.div`
  width: 100%;
  height: calc(100% - 91px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  input {
    width: 100%;
    height: 40px;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 8px;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 16px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#81c242')};
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: relative;
  img {
    position: absolute;
    left: 10%;
  }
  span {
    font-size: 1rem;
  }
`;

export const Toast = styled.div`
  width: 70%;
  height: 60px;
  border-radius: 8px;
  background-color: ${({ color }) => color};
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  padding: 4px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: ${({ show }) => (show !== '' ? '20px' : '-60px')};
  transition: all 0.3s ease;

  .close {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    /* position: absolute;
    left: 10px; */
    cursor: pointer;
    font-size: 1.5rem;
    color: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(45deg);
  }
  img {
    /* position: absolute;
    left: 20%; */
  }
  .msg {
    /* position: absolute;
    left: 20px; */
    padding: 0 20px;
    width: 100%;
    text-align: left;
    font-size: 1rem;
  }
`;
