import React from 'react';

function ArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="20"
      viewBox="0 0 13.519 21.923"
    >
      <path
        fill="#545454"
        d="M72.169 2.558L69.612 0 58.65 10.962l10.962 10.961 2.558-2.558-8.4-8.4z"
        data-name="Caminho 429"
        transform="translate(-58.65)"
      />
    </svg>
  );
}

export default ArrowIcon;
